import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Nav, NavbarContainer, NavLogo } from './NavbarElements';
import { animateScroll as scroll } from 'react-scroll';

// Add MobileIcon from './NavbarElements';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(false)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to='/' onClick={toggleHome}>SarahRO</NavLogo>
            {/* <NavLogo to={{ pathname: "" }} target="_blank">Docs(Coming Soon)</NavLogo>
            <NavLogo to={{ pathname: "" }} target="_blank">Swap xSRO(Coming Soon)</NavLogo>
            <NavLogo to={{ pathname: "" }} target="_blank">APPS(Coming Soon)</NavLogo>
            <MobileIcon onClick={toggle}> */}
            <FaBars />
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
